<template>
  <div class="columns">
    <div class="column content">
      <h4 class="is-size-6 is-marginless">
        E-mail:
      </h4>
      <p>{{ userData.emailAddress }}</p>
      
      <h4 class="is-size-6 is-marginless">
        First Name:
      </h4>
      <p>{{ userData.firstName }}</p>

      <h4 class="is-size-6 is-marginless">
        Last Name:
      </h4>
      <p>{{ userData.lastName }}</p>

      <h4 class="is-size-6 is-marginless">
        Phone Number:
      </h4>
      <p>{{ userData.phoneNumber }}</p>

      <template v-if="userData.phoneExtension">
        <h4 class="is-size-6 is-marginless">
          Phone Extension:
        </h4>
        <p>{{ userData.phoneExtension }}</p>
      </template>

      <template v-if="userData.title">
        <h4 class="is-size-6 is-marginless">
          Title:
        </h4>
        <p>{{ userData.title }}</p>
      </template>

      <template v-if="userData.employeeNumber">
        <h4 class="is-size-6 is-marginless">
          Employee Number:
        </h4>
        <p>{{ userData.employeeNumber }}</p>
      </template>

      <template v-if="userData.departmentNumber">
        <h4 class="is-size-6 is-marginless">
          Department #:
        </h4>
        <p>{{ userData.departmentNumber }}</p>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserForm",
  components: {
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>