<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <users-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="columns">
                  <div class="column">
                    <h3 class="has-text-centered-mobile is-marginless">
                      {{ getTitle() }}
                    </h3>
                  </div>
                  <div class="column has-text-right has-text-centered-mobile">
                    <button
                      v-if="curUserType === $ADMINISTRATOR || curUserType === $ACHADMINISTRATOR"
                      class="button is-primary"
                      @click="addNew"
                    >
                      Add User
                    </button>
                  </div>
                </div>
                <div :class="{ 'hide-edges': hideEdges }">
                  <v-client-table
                    ref="allUsers"
                    :columns="tableColumns"
                    :options="tableOptions"
                    :data="tableValues"
                  >
                    <template
                      slot="registeredDate"
                      slot-scope="props"
                    >
                      {{ props.row.registeredDate }}
                    </template>
                    <template
                      slot="lastLoginDate"
                      slot-scope="props"
                    >
                      {{ props.row.lastLoginDate }}
                    </template>
                    <template
                      slot="modifiedDate"
                      slot-scope="props"
                    >
                      {{ props.row.modifiedDate }}
                    </template>
                    <template
                      slot="emailAddress"
                      slot-scope="props"
                    >
                      <a
                        href="#"
                        @click.prevent="editView(props.row)"
                      >{{ props.row.emailAddress }}</a>
                    </template>
                    <template
                      slot="fullName"
                      slot-scope="props"
                    >
                      {{ props.row.firstName }} {{ props.row.lastName }}
                    </template>
                    <template
                      v-if="userType === $USER"
                      slot="actions"
                      slot-scope="props"
                    >
                      <button
                        v-if="String(props.row.status).indexOf('Pending') !== -1"
                        class="button is-small is-success"
                        :class="{ 'is-loading': doingAjaxFlag[props.row.id] }"
                        title="Activate User"
                        @click="changeStatus(props.row, 'Valid')"
                      >
                        Activate
                      </button>

                      <button
                        v-if="String(props.row.status).indexOf('Pending') === -1"
                        class="button is-small is-grey"
                        :class="{ 'is-loading': doingAjaxFlag[props.row.id] }"
                        title="Deactivate User"
                        @click="changeStatus(props.row, 'PendingApproval')"
                      >
                        Deactivate
                      </button>
                    </template>
                  </v-client-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      ref="edit-user"
      class="is-small"
      :settings="{ title: getModalTitle() }"
    >
      <registration-form
        :cur-screen="`admin-${eventType}`"
        :user-type="userType"
        :form-data="userAdminObject"
        @hide="hideModal"
        @form-submit="submitForm"
        @delete-user="deleteUser"
      />
    </modal>
    <modal
      ref="view-user"
      class="is-small"
      :settings="{ title: `User Data` }"
    >
      <view-user-data
        :user-data="userAdminObject"
        @hide="hideModal"
      />
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/common/Modal';
import RegistrationForm from '@/components/RegistrationForm';
import ViewUserData from '@/components/ViewUserData';
import UsersTabs from '@/components/UsersTabs';
import constants from '@/config/constants';

import { options } from '@/config/tableOptions';

const globalColumns = () => {
  return [
    "emailAddress",
    "fullName",
    "registeredDate",
    "lastLoginDate",
    "modifiedBy",
    "modifiedDate",
  ];
};

const globalColumnsAdmin = () => {
  return [
    "ein",
    "emailAddress",
    "fullName",
    "registeredDate",
    "lastLoginDate",
    "modifiedBy",
    "modifiedDate",
  ];
};

export default {
  name: "VendorUsers",
  components: {
    Modal,
    RegistrationForm,
    UsersTabs,
    ViewUserData,
  },
  props: {
    userType: {
      type: String,
      default: constants.USER,
    },
  },
  data() {
    return {
      eventType: null,
      doingAjaxFlag: {},
      tableOptions: options({
        headings: {
          ein: "EIN/SSN",
          emailAddress: "User Name",
          employeeNumber: "Employee #",
          fullName: "Full Name",
          departmentNumber: "Department #",
          registeredDate: "Registered\nDate",
          lastLoginDate: "Last Login\nDate",
          modifiedBy: "Modified By",
          modifiedDate: "Modified\nDate",
        },
        columnsClasses: {
          lastLoginDate: 'date',
          registeredDate: 'date',
          modifiedDate: 'date',
          emailAddress: 'td-nowrap',
          modifiedBy: 'td-nowrap',
          actions: 'actions',
        },
        sortable: [ 'emailAddress', 'employeeNumber', 'departmentNumber' ],
        dateFormat: "MM/DD/YYYY HH:mm",
        resizableColumns: true,
      }),
    };
  },
  computed: {
    tableValues() {
      if (this.userType === this.$USER) {
        return this.$store.state.users.users;
      }
      if (this.userType === this.$DEPTADMIN) {
        return this.$store.state.users.userAdmins;
      }
      return [];
    },
    userAdminObject() {
      return JSON.parse(JSON.stringify(this.$store.state.users.userAdminObject));
    },
    tableColumns() {
      let tableColumns;
      // console.log('in tableColumns computed, this.$route.name:', this.$route.name);
      if (this.$isAnyAdmin(this.curUserType) && this.$route.name === 'vendor-users') {
        tableColumns = globalColumnsAdmin();
      } else {
        tableColumns = globalColumns();
      }

      if (this.$isAnyAdmin(this.curUserType)) {
        if (this.userType === this.$DEPTADMIN) {
          tableColumns.push("employeeNumber");
        }
      } else {
        tableColumns.push("actions");
      }
      return tableColumns;
    },
  },
  async fetch({ store }) {
    await store.dispatch('users/getUsers');
  },
  methods: {
    getTitle() {
      if (this.userType === this.$USER) {
        return 'Users List';
      }
      if (this.userType === this.$DEPTADMIN) {
        return 'Application Users List';
      }
    },
    getModalTitle() {
      if (this.userType === this.$USER) {
        return 'User Form';
      }
      if (this.userType === this.$DEPTADMIN) {
        return 'Application User Form';
      }
    },
    editView(user) {
      // console.log(user);
      if (this.curUserType === this.$ADMINISTRATOR || this.curUserType === this.$ACHADMINISTRATOR) {
        this.edit(user);
      } else {
        this.view(user);
      }
    },
    view(user) {
      this.eventType = 'view';
      this.$store.commit('users/setUserAdminObject', user);
      this.$refs['view-user'].show();
    },
    edit(user) {
      this.eventType = 'edit';
      this.$store.commit('users/setUserAdminObject', user);
      this.$refs['edit-user'].show();
    },
    addNew() {
      this.eventType = 'add';
      this.$store.commit('users/cleanUserAdminObject');
      this.$refs['edit-user'].show();
    },
    hideModal() {
      this.$refs['edit-user'].hide();
      this.$refs['view-user'].hide();
    },
    async submitForm(formData) {
      console.log('VendorUsers.vue submitForm is running, this:', this, 'formData:', formData);
      // Remove the password, it has to be null for creation and udpate.
      delete formData.password;

      if (this.eventType === 'edit') {
        console.log('eventType is edit, formData:', formData);
        const response = await this.$store.dispatch('users/update', formData);
        if (response) {
          this.$success(`The user ${response.emailAddress} has been successfully updated.`);
          this.hideModal();
        }
      } else if (this.eventType === 'add') {
        // I think we should use Endpoints just to create admins instead of sending the role from front-end
        // formData.role = this.$USER;
        formData.role = this.userType;
        const response = await this.$store.dispatch('users/add', formData);
        if (response) {
          this.$success(`The user ${response.emailAddress} has been successfully created.`);
          this.hideModal();
        }
      } else {
        this.$fatalError();
      }
    },

    deleteUser(formData) {
      this.$modal.show('dialog', {
        title: 'Wait...!',
        text: `Are you sure you want to delete the user ${formData.emailAddress}?`,
        buttons: [
          {
            title: 'Yes, of Course!',
            handler: async () => {
              await this.$store.dispatch('users/delete', formData);
              this.$modal.hide('dialog');
              this.hideModal();
            },
          },
          {
            title: 'No',
            default: true,
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },

    changeStatus(user, action) {
      const status = (action === 'Valid') ? 'Active' : 'Inactive';
      this.$modal.show('dialog', {
        title: 'Before you proceed..',
        text: `Would you make the <strong>${user.firstName}</strong>: ${status}?`,
        buttons: [
          {
            title: 'Yes',
            handler: async () => {
              this.$set(this.doingAjaxFlag, user.id, true);
              this.$modal.hide('dialog');

              await this.$store.dispatch('users/updateUserStatus', {
                userId: user.id,
                status: action,
                curUserType: this.curUserType,
              });

              this.doingAjaxFlag[user.id] = false;
            },
          },
          {
            title: 'No',
            default: true,
          },
        ],
      });
    },
  },
};
</script>
